import axios from 'axios'

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
axios.defaults.xsrfCookieName = "csrftoken"
axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_AXIOS_BASEURL

const HitsbookSaaSAPI = {
  getCookie (name) {
    let match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
  },
  getConfig (data) {
    var config = { 'headers': {} }
    if (data) {
      config['headers']['Content-Type'] = 'multipart/form-data'
    }
    return config
  },
  getPayload (data) {
    var payload = new FormData()
    for (var key in data) {
      if (Array.isArray(data[key])) {
        data[key].forEach(item => {
          payload.append(key, item)
        })
      } else {
        payload.set(key, data[key])
      }
    }
    payload.set('csrfmiddlewaretoken', this.getCookie('csrftoken'))
    return payload
  },
  getProducts () {
    return axios.get('/products/')
  },
  sendRequest (data) {
    let payload = this.getPayload(data)
    let config = this.getConfig(payload)
    return axios.post('/custom_plan_contact', payload, config)
  },
  checkoutTrial (data) {
    let payload = this.getPayload(data)
    let config = this.getConfig(payload)
    return axios.post('/checkout', payload, config)
  }
}

export default HitsbookSaaSAPI
