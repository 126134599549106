<template>
  <div>
    <nav
      class="navbar navbar-expand-lg navbar-saas fixed-top no-shadow"
      v-if="mode !== 'change'"
    >
      <!-- <div class="alert alert-header">{{ staticTexts.discount }}</div> -->
      <div class="container">
        <div class="navbar-wrapper">
          <a
            class="navbar-brand navbar-brand-compo m-0"
            href="https://www.hitsbook.tv"
          >
            <img
              class="hitsbook-ico"
              src="/static/img/brand/hitsbook-ico-color.svg"
            />
            <img
              class="hitsbook-name hitsbook-display"
              src="/static/img/brand/hitsbook-name-color.svg"
            />
          </a>
        </div>
        <a
          class="btn btn-warning btn-link btn-login"
          href=""
          onclick="Calendly.initPopupWidget({url: 'https://calendly.com/demo-hits-book/demo-hits-book'});return false;"
          v-if="mode === 'saas'"
        >
          <em>{{ staticTexts.haveAnAccount }}</em>
          <span class="material-icons">
            desktop_windows
          </span>
          {{ staticTexts.initSession }}
          <span></span>
        </a>
      </div>
    </nav>
    <!-- END / Special header-->
    <div class="wrapper wrapper-full-page wrapper-ohidden wrapper-full-height">
      <div :class="$route.meta.containerClass">
        <!-- CONTENT -->
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <router-view></router-view>
        </fade-transition>
        <!-- END / CONTENT -->
      </div>
      <footer class="page-footer page-footer--center" v-if="mode !== 'change'">
        <p class="text-center">
          <span class="mr-3">© 2021 Hits Book</span>
          <a target="_blank" href="/legal/">{{ staticTexts.legal1 }}</a> |
          <a target="_blank" href="/terminos/">{{ staticTexts.legal2 }}</a> |
          <a target="_blank" href="/cookies/">{{ staticTexts.legal3 }}</a>
        </p>
        <TheLanguageSelector direction="up" />
      </footer>
    </div>
    <portal-target name="modals"></portal-target>
  </div>
</template>

<script>
/* global gettext */
/* global MODE */
import { FadeTransition } from "vue2-transitions";
import TheLanguageSelector from "../../../common_components/TheLanguageSelector";

export default {
  components: {
    FadeTransition,
    TheLanguageSelector
  },
  data() {
    return {
      mode: "saas"
    };
  },
  computed: {
    staticTexts: function() {
      return {
        discount: gettext("Descuento de lanzamiento del -30%"),
        haveAnAccount: gettext("Solicita tu"),
        initSession: gettext("Demo gratis"),
        watchVideos: gettext("Ver vídeos"),
        participate: gettext("Participa"),
        ranking: gettext("Ranking"),
        company: gettext("Compañía"),
        legal1: gettext("Política de privacidad"),
        legal2: gettext("Términos y condiciones"),
        legal3: gettext("Política de cookies")
      };
    }
  },
  methods: {},
  mounted() {
    // console.log("MODE: ", MODE)
    this.mode = MODE;
  }
};
</script>

<style lang="scss"></style>
