import Vue from 'vue'
import Vuex from 'vuex'

import HitsbookSaaSAPI from '../api/api.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    products: [],
    showCustomPlanModal: false,
    period: "month"
  },
  mutations: {
    setProducts: (state, payload) => {
      state.products = payload
    },
    setShowCustomPlanModal: (state, newValue) => {
      state.showCustomPlanModal = newValue
    },
    togglePeriod: (state) => {
      if (state.period === "month") {
        state.period = "year"
      } else {
        state.period = "month"
      }
    }
  },
  actions: {
    loadProducts: (context) => {
      return HitsbookSaaSAPI.getProducts().then((response) => {
        context.commit('setProducts', response.data)
      })
    }
  },
  getters: {
    getProducts: (state) => {
      return state.products.results
    },
    getShowCustomPlanModal: (state) => {
      return state.showCustomPlanModal
    },
    getPeriod: (state) => {
      return state.period
    }
  }
})
