import Vue from 'vue'
import VueRouter from 'vue-router'
import SaaSLayout from '@/layouts/SaaSLayout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: SaaSLayout,
    children: [
      {
        path: '/',
        name: 'choose-plan',
        component: () => import(/* webpackChunkName: "hitsbooksaas" */ '../views/ChoosePlan.vue'),
        meta: {
          "containerClass": "",
          "variant": "default"
        }
      },
      {
        path: '/contratacion-campanas-rrss',
        name: 'choose-plan-rrss',
        component: () => import(/* webpackChunkName: "hitsbooksaas" */ '../views/ChoosePlan.vue'),
        meta: {
          "containerClass": "container-fluid",
          "variant": "rrss"
        }
      },
      {
        path: '/contratacion-concurso',
        name: 'choose-plan-contests',
        component: () => import(/* webpackChunkName: "hitsbooksaas" */ '../views/ChoosePlan.vue'),
        meta: {
          "containerClass": "container-fluid",
          "variant": "contests"
        }
      },
      {
        path: '/contratacion-castings',
        name: 'choose-plan-castings',
        component: () => import(/* webpackChunkName: "hitsbooksaas" */ '../views/ChoosePlan.vue'),
        meta: {
          "containerClass": "container-fluid",
          "variant": "castings"
        }
      },
      {
        path: '/trial/:code/:interval',
        name: 'trynow',
        component: () => import(/* webpackChunkName: "hitsbook" */ '../views/TryNow.vue'),
        meta: {
          "containerClass": "container pt-5"
        }
      },
      {
        path: '/trial/trynow',
        name: 'trynowgeneric',
        component: () => import(/* webpackChunkName: "hitsbook" */ '../views/TryNow.vue'),
        meta: {
          "containerClass": "container pt-5"
        }
      },
      {
        path: '/success',
        name: 'success',
        component: () => import(/* webpackChunkName: "hitsbooksaas" */ '../views/Congrats.vue'),
        meta: {
          "containerClass": "container pt-5"
        }
      },
      {
        path: '/cancel',
        name: 'cancel',
        component: () => import(/* webpackChunkName: "hitsbooksaas" */ '../views/Cancel.vue'),
        meta: {
          "containerClass": "container pt-5"
        }
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
