/* global gettext */
import * as rules from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
import { messages } from 'vee-validate/dist/locale/es.json';

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  })
})

extend('acceptance', {
  computesRequired: true,
  validate: (value) => {
    return value === 'accepted'
  },
  message: gettext('Es obligatorio aceptar esto para poder continuar')
})

extend('checkboxrequired', {
  computesRequired: true,
  validate: (value) => {
    return value === true
  },
  message: gettext('Es obligatorio aceptar esto para poder continuar')
})
