// Notifications plugin. Used on Notifications page
// import Notifications from "@/components/NotificationPlugin";
// A plugin file where you could register global components used across the app
import GlobalComponents from "./globalComponents";
// A plugin file where you could register global directives
import GlobalDirectives from "./globalDirectives";
// Tabs plugin. Used on Panels page.
import VueMaterial from "vue-material";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2'

// asset imports
// import "vue-material/dist/vue-material.min.css";
import "../../../assets/scss/material-dashboard.scss"
import "@fortawesome/fontawesome-free/scss/fontawesome.scss"
import "@fortawesome/fontawesome-free/scss/solid.scss"
import "@fortawesome/fontawesome-free/scss/brands.scss"

import 'sweetalert2/dist/sweetalert2.min.css'

// import "../../../assets/css/demo.css
// library auto imports
import "es6-promise/auto";

export default {
  install (Vue) {
    Vue.use(GlobalComponents)
    Vue.use(GlobalDirectives)
    Vue.use(VueMaterial)
    // Vue.use(Notifications)
    // Install BootstrapVue
    Vue.use(BootstrapVue)
    Vue.use(IconsPlugin)
    Vue.use(VueSweetalert2)
  }
};
